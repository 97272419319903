import anime from "animejs";
import {
  relativeToPageIndex,
  ScrollerContext,
} from "components/Scroller/Scroller";
import {
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import uuid from "react-uuid";
import { ScrollStatus } from "smooth-scrollbar/interfaces";
import "./page.less";

export const Page = (
  props: PropsWithChildren<
    {
      combinePages?: number;
      overflow?: string;
      page?: number;
      url?: string;
      layer?: boolean;
      scale?: boolean;
      color?: string;
      animeBackground?: boolean;
      backgroundLayer?: string;
      parallax?: number;
      horizontal?: boolean;
    } & HTMLDivElement &
      any
  >
) => {
  const pageRef = useRef<HTMLDivElement>(null);
  const parallax = props.parallax ?? 1.4;
  const { initScroller } = useContext(ScrollerContext);
  const [ableAnime, setAbleAnime] = useState(!!props.animeBackground);
  const [id] = useState(uuid());

  const handleAnime = (e: ScrollStatus) => {
    const scrollYPagePosition = relativeToPageIndex(e, props.page);
    if (scrollYPagePosition < 0) {
      anime.remove([`.page-${id} .image-bg`, `.page-${id} .layer`]);
    }
    if (scrollYPagePosition <= window.innerHeight) {
      anime.set(`.page-${id} .image-bg`, {
        ...(!props.horizontal && {
          translateY: scrollYPagePosition * parallax,
        }),
        ...(props.horizontal && {
          translateY: scrollYPagePosition,
          translateX: scrollYPagePosition * parallax,
        }),
      });
      props.layer &&
        anime.set(`.page-${id} .layer`, {
          opacity: Math.abs(scrollYPagePosition / window.innerHeight - 1),
        });
    } else if (props.scale && scrollYPagePosition <= window.innerHeight * 2) {
      anime.set(`.page-${id} .image-bg`, {
        scale:
          1 + (scrollYPagePosition - window.innerHeight) / window.innerHeight,
      });
    }
  };

  useEffect(() => {
    if (ableAnime) {
      const scroller = initScroller();
      scroller && scroller.on("scroll", handleAnime);
    }
  }, []);

  return (
    <div
      ref={pageRef}
      className={`page bg-black ${props.className} page-${id}`}
      style={{
        backgroundColor: props.color ?? "revert-layer",
        height: props.combinePages ? 100 * props.combinePages + "vh" : "100vh",
        ...(props.overflow ? { overflow: props.overflow } : null),
        ...(props.backgroundPosition
          ? { backgroundPosition: props.backgroundPosition }
          : null),
      }}
    >
      {props.url && (
        <div
          className="image-bg"
          style={{
            backgroundImage: `url("${props.url}")`,
            ...(props.backgroundImageStyle ? props.backgroundImageStyle : null),
          }}
        />
      )}
      {props.layer && (
        <div className="layer" style={{ background: props.backgroundLayer }} />
      )}
      <div style={{ zIndex: 1, height: "100vh" }}>{props.children}</div>
    </div>
  );
};

export const HorizontalPages = (props: PropsWithChildren<any>) => {
  const [active, setActive] = useState(false);
  const [pagesNumber, setPagesNumber] = useState(props.children.length ?? 1);
  const { initScroller } = useContext(ScrollerContext);

  const [progress, setProgress] = useState(0);

  const animePage = (e: ScrollStatus) => {
    props.children.forEach((_: any, i: number) => {
      if (!i) return;
      const scrollYHorizontalPagePosition = relativeToPageIndex(e, props.page);
      const el = document.getElementById(`horizon-${i}`);
      const isActive = el?.className.includes("horizon-active");
      if (
        scrollYHorizontalPagePosition <
        (window.innerHeight * i) / pagesNumber
      ) {
        if (isActive) {
          anime({
            targets: `#horizon-${i}`,
            translateX: [0, window.innerWidth],
            duration: 1000,
            easing: "easeInExpo",
          });
          el?.classList.remove("horizon-active");
        }
      } else {
        if (!isActive) {
          anime({
            targets: `#horizon-${i}`,
            translateX: [window.innerWidth, 0],
            duration: 1000,
            easing: "easeOutExpo",
            className: "horizon-active ",
          });
        }
      }
    });
  };

  const handleAnime = (e: ScrollStatus) => {
    const scrollYPagePosition = relativeToPageIndex(e, props.page);
    if (scrollYPagePosition > 0 && scrollYPagePosition < window.innerHeight) {
      // on stop la progression de la page pour qu'elle
      // soit static au scroll
      // On lui donne une height de pageNumber page

      anime.set(".horizontal-pages-container", {
        translateY: scrollYPagePosition,
      });
      animePage(e);
    } else if (scrollYPagePosition <= 0) {
      anime.set(".horizontal-pages-container", { translateY: 0 });
    } else if (scrollYPagePosition >= window.innerHeight)
      anime.set(".horizontal-pages-container", {
        translateY: window.innerHeight,
      });
  };

  useEffect(() => {
    if (props.children.length) {
      setActive(true);
      const scroller = initScroller();
      scroller && scroller.on("scroll", handleAnime);
    }
  }, []);

  if (!active) return props.children;
  return (
    <div
      className="horizontal-pages-container"
      style={{
        // height: `${window.innerWidth * pagesNumber}px`,
        height: `${window.innerHeight * 2}px`,
        // vw car la height de la page-container correspond à
        // l'addition de la width de toutes les pages
      }}
    >
      {props.children.map((child: any, index: number) => (
        <div
          key={index}
          style={{
            transform: `translateY(-${100 * index}vh) ${
              index ? "translateX(100vw)" : ""
            }`,
          }}
          id={`horizon-${index}`}
          className={`horizontal-page horizon-${index}`}
        >
          {child}
        </div>
      ))}
    </div>
  );
};
