export const wordsByLength = {
  1: [
    "a",
    "i",
    "o",
    "u",
    "e",
    "y",
    "m",
    "s",
    "k",
    "t",
    "x",
    "n",
    "p",
    "r",
    "z",
    "b",
    "c",
    "d",
    "g",
    "h",
    "j",
    "l",
    "v",
    "w",
    "q",
    "f",
  ],
  2: [
    "in",
    "no",
    "go",
    "is",
    "on",
    "to",
    "by",
    "as",
    "it",
    "he",
    "we",
    "at",
    "be",
    "an",
    "of",
    "do",
    "so",
    "or",
    "am",
    "me",
    "up",
    "us",
    "if",
    "my",
    "oh",
    "ok",
    "hi",
    "ye",
    "ah",
    "ha",
  ],
  3: [
    "yes",
    "raw",
    "cow",
    "cat",
    "dog",
    "bat",
    "bed",
    "car",
    "map",
    "man",
    "cap",
    "nap",
    "run",
    "top",
    "sit",
    "lot",
    "jet",
    "box",
    "red",
    "sun",
    "log",
    "dig",
    "big",
    "net",
    "let",
    "fit",
    "kit",
    "rot",
    "bay",
  ],
  4: [
    "tree",
    "love",
    "cake",
    "bird",
    "fish",
    "door",
    "fire",
    "game",
    "rock",
    "snow",
    "time",
    "wind",
    "leaf",
    "lamp",
    "ball",
    "ship",
    "book",
    "king",
    "ring",
    "star",
    "lion",
    "wolf",
    "hand",
    "hope",
    "play",
    "rain",
    "song",
    "walk",
    "move",
    "free",
  ],
  5: [
    "apple",
    "grape",
    "house",
    "chair",
    "table",
    "smile",
    "ocean",
    "earth",
    "light",
    "space",
    "green",
    "water",
    "stone",
    "plane",
    "tower",
    "grass",
    "dance",
    "river",
    "party",
    "dream",
    "happy",
    "faith",
    "world",
    "quick",
    "giant",
    "sharp",
    "eagle",
    "funny",
    "music",
    "heart",
  ],
  6: [
    "planet",
    "orange",
    "forest",
    "island",
    "friend",
    "silver",
    "candle",
    "rocket",
    "circus",
    "dancer",
    "castle",
    "bridge",
    "hunter",
    "clouds",
    "garden",
    "singer",
    "pepper",
    "shovel",
    "memory",
    "butter",
    "kitten",
    "temple",
    "prince",
    "bucket",
    "school",
    "farmer",
    "window",
    "spider",
    "breeze",
    "summer",
  ],
  7: [
    "freedom",
    "picture",
    "diamond",
    "journey",
    "fortune",
    "captain",
    "village",
    "science",
    "weather",
    "monster",
    "factory",
    "holiday",
    "teacher",
    "contest",
    "concert",
    "seasons",
    "justice",
    "mystery",
    "courage",
    "morning",
    "sunrise",
    "mission",
    "lecture",
    "fortune",
    "passion",
    "express",
    "kingdom",
    "library",
    "respect",
    "harmony",
  ],
  8: [
    "absolute",
    "activity",
    "birthday",
    "building",
    "champion",
    "computer",
    "elephant",
    "football",
    "goldfish",
    "hospital",
    "language",
    "marriage",
    "medicine",
    "mountain",
    "painting",
    "pleasure",
    "reaction",
    "scissors",
    "solution",
    "sunshine",
    "umbrella",
    "vacation",
    "velocity",
    "warplane",
    "wondered",
    "wrapping",
    "question",
    "division",
    "learning",
    "tropical",
  ],
};
