import { Code } from "pages/Code/Code";
import { useNavigate, useParams } from "react-router-dom";
import "styles/App.less";

function App() {
  const navigate = useNavigate();
  let { password } = useParams();
  return (
    <div>
      <div className="flex-center">
        <Code
          password={password || "123456"}
          onSolved={() => {
            navigate("/play");
          }}
        />
      </div>
    </div>
  );
}

export default App;
