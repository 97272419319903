import { Action } from "redux";

const initial_state = {
  loading: false,
  success: false,
  codeReceived: false,
  validEmail: false,
  loggedIn: false, // code and email oK
  title: "Enter email",
};
export const adminStore = (state = initial_state, action: Action) => {
  switch (action.type) {
    case "ADMIN_EMAIL_REQUEST":
      return { ...state, loading: true };
    case "ADMIN_CODE_REQUEST":
      return {
        ...state,
        title: "let's see...",
        loading: true,
        resetCode: false,
      };
    case "ADMIN_EMAIL_SUCCESS":
      return {
        ...state,
        title: "Enter code",
        loading: false,
        validEmail: true,
        codeReceived: true,
      };
    case "ADMIN_CANCEL_REQUEST":
      return {
        ...initial_state,
      };
    case "ADMIN_CODE_SUCCESS":
      return {
        ...state,
        title: "Well done.",
        loggedIn: true,
        loading: false,
      };
    case "ADMIN_CODE_ERROR":
      return {
        ...state,
        loading: false,
        title: "No. Enter code",
        resetCode: true,
      };
    default:
      return state;
  }
};
