import { CheckCircleOutlined, SyncOutlined } from "@ant-design/icons";
import { Button, Form, Input, Space } from "antd";
import { DestructuredLogo } from "components/DestructuredLogo/DestructuredLogo";
import { Code } from "components/Inputs/Code/Code";
import { Previous } from "components/Previous/Previous";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import TextTransition from "react-text-transition";
import "./admin.less";
import actions from "./services/actions";

export const Admin = (props: any) => {
  const states = useSelector((store: any) => store.adminStore);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [movement, setMovement] = useState("initial");
  const [email, setEmail] = useState<string | null>();
  const [formRef] = Form.useForm();
  const [emailInputIcon, setEmailInputIcon] = useState<any>(null);

  const onFinish = (event: { email: string; code?: string }) => {
    if (states.codeReceived && event.code)
      dispatch(actions.code({ code: event.code, email: event.email }));
    else {
      dispatch(actions.email({ email: event.email }));
    }
  };

  const cancelEmail = () => {
    dispatch(actions.cancel());
    setMovement("initial");
    formRef.resetFields();
    setEmailInputIcon(null);
  };

  useEffect(() => {
    if (states.loading) setEmailInputIcon(<SyncOutlined spin />);
    else if (states.validEmail) setEmailInputIcon(<CheckCircleOutlined />);
    else setEmailInputIcon(null);
  }, [states.loading]);

  useEffect(() => {
    if (states.validEmail) {
      setEmailInputIcon(<CheckCircleOutlined />);
      setMovement("destroy");
    }
  }, [states.validEmail]);

  useEffect(() => {
    if (states.resetCode) {
      formRef.resetFields(["code"]);
    }
  }, [states.resetCode]);

  useEffect(() => {
    if (states.loggedIn) {
      setEmailInputIcon(<CheckCircleOutlined />);
      setMovement("initial");
      setTimeout(() => {
        // navigate("/meytvassa")
      }, 2000);
    }
  }, [states.loggedIn]);
  return (
    <div className="flex-column">
      <DestructuredLogo
        loading={states.validEmail && states.loading}
        movement={movement}
      />

      <Form form={formRef} onFinish={onFinish} style={{ width: "200px" }}>
        <Space direction="vertical">
          {states.codeReceived && <Previous onClick={cancelEmail} />}
          <h3 className="outlined white">
            <TextTransition>{states.title}</TextTransition>
          </h3>
          <Form.Item name="email">
            <Input
              type="email"
              disabled={states.codeReceived || states.loading}
              className={`glass-input`}
              suffix={emailInputIcon}
            />
          </Form.Item>
          {states.codeReceived && (
            <Form.Item name="code">
              <Code
                length={4}
                className={`glass-input`}
                disabled={states.loading || states.loggedIn}
                onComplete={(code: string) => {
                  formRef.setFieldValue("code", code);
                  formRef.submit();
                }}
              />
            </Form.Item>
          )}
        </Space>
        <button type="submit" hidden></button>
      </Form>
      {states.loggedIn && (
        <Button type="primary" onClick={() => navigate("/welcome")}>
          Continue
        </Button>
      )}
    </div>
  );
};
