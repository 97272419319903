export const NotFound = () => {
  return (
    <div className="main-container">
      <div className="flex-center">
        <div className="flex-column">
          <h1 className="gradient-emerald-text">404</h1>
          <h1 className="gradient-emerald-text">Hum. Sure?</h1>
          <h2 className="gradient-purple-text">I think you forgot stg :/</h2>
        </div>
      </div>
    </div>
  );
};
