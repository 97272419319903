const actions = {
  cancel: () => ({ type: "ADMIN_CANCEL_REQUEST" }),
  email: (payload: { email: string }) => ({
    type: "ADMIN_EMAIL_REQUEST",
    payload,
  }),
  email_success: () => ({
    type: "ADMIN_EMAIL_SUCCESS",
  }),
  code: (payload: { code: string; email: string }) => ({
    type: "ADMIN_CODE_REQUEST",
    payload,
  }),
  code_success: () => ({ type: "ADMIN_CODE_SUCCESS" }),
  code_error: () => ({ type: "ADMIN_CODE_ERROR" }),
};
export default actions;
