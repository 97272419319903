import anime from "animejs";
import { Flag3D } from "components/3DModels/Flag";
import { MyScene } from "components/3DModels/Scene";
import { Page } from "components/Contents";
import { P } from "components/Html";
import {
  relativeToPageIndex,
  ScrollerContext,
} from "components/Scroller/Scroller";
import { useContext, useEffect, useState } from "react";
import uuid from "react-uuid";
import { ScrollStatus } from "smooth-scrollbar/interfaces";

export const FirstParagraph = (props: any) => {
  return (
    <Paragraph
      overflow={"hidden"}
      layer
      backgroundLayer="linear-gradient(0deg, transparent 0%, #010719 100%)"
      animeBackground
      parallax={0.8}
      scale
      url="/images/sea.avif"
      {...props}
      assets={
        <Asset {...props}>
          <MyScene manual={true}>
            <Flag3D />
          </MyScene>
        </Asset>
      }
    >
      Les cent-soixante-dix-sept navires Mëlandals et ses premiers milliers
      d'Hommes verront bientôt les hautes côtes escarpées des Eyriennes après de
      longs mois passé en mer. Derrière elles une forêt s'étend, noire, dressée,
      puissante.
      <br />
      Quelques navires appartiennent à la famille Naqéande. Pavillon blanc au
      lion tracé rainant les flots, sur l'un d'eux une respectueuse donnait
      naissance à Yadehi.
    </Paragraph>

    // Ajouter image de flotte avançant vers nous
  );
};

export const SecondParagraph = (props: any) => {
  return (
    <Paragraph {...props}>
      Hors des regards sales des factionnaires de quart, seule dans sa cage et
      sans un bruit, à la fraiche nuit où danse quelques flammes, elle mit-bas.
      La lame émoussée désagrega le cordon qu'elle empoigna, chiard balancé, se
      dirigera vers le pont. La tête du nouveau né hors du navire, comme la
      coutume l'y lui réclamait elle le baptisa avant de l'envoyer au fond des
      eaux.
      <br />
      Sur le pont, Jado Bethlin, l’un des hommes les plus influents du Mëlandal,
      surveillait les mers.
      <br />
      « garde ton enfant, dit-il. La terre est en vue. Il sera le premier sauf
      ».
      <br />
      Puis chargea qu'on démarre les sémaphore.
    </Paragraph>
    // Ajouter image de sémaphore de bateau
  );
};

export const Asset = (props: any) => {
  const { initScroller } = useContext(ScrollerContext);
  const [id] = useState(uuid());

  useEffect(() => {
    const scroller = initScroller();
    scroller && scroller.on("scroll", handleAnime);
  }, []);

  const handleAnime = (e: ScrollStatus) => {
    const scrollYPagePosition = relativeToPageIndex(e, props.page);
    // if (props.page === 2) {
    if (scrollYPagePosition > 0) {
      anime.set(`.asset-${id}`, {
        translateY: scrollYPagePosition * -0.2,
      });
    }
  };
  return (
    <div style={{ zIndex: 10 }} className={`paragraph-asset asset-${id}`}>
      {props.children}
    </div>
  );
};

export const ThirdParagraph = (props: any) => {
  return (
    <Paragraph {...props}>
      Les paroles de Mëlya sont claires : aucune naissance sur un navire.
      <br />
      <br />
      <br />
      Meytvassa a sauvé Yadehi des griffes iniques de l'océan. Quelques dizaines
      d'autres avant lui n'ont pas eu cette chance.
    </Paragraph>
  );
};

export const Paragraph = (props: any) => {
  const { initScroller } = useContext(ScrollerContext);
  useEffect(() => {
    const scroller = initScroller();
    scroller && scroller.on("scroll", handleAnime);
  }, []);

  const handleAnime = (e: ScrollStatus) => {
    const scrollYPagePosition = relativeToPageIndex(e, props.page);
    const startAt = 0;
    if (scrollYPagePosition < startAt) {
      anime.set(`.page-${props.page}`, {
        translateY: 0,
      });
    }
    if (
      scrollYPagePosition > startAt &&
      scrollYPagePosition < window.innerHeight
    ) {
      anime.set(`.page-${props.page}`, {
        translateY: scrollYPagePosition * 0.8,
      });
    } else if (-window.innerHeight - scrollYPagePosition > 200) {
      anime.remove(`.page-${props.page}`);
    }
  };
  return (
    <Page
      className={`${props.shadow ? "shadow-top" : ""} page-${props.page}`}
      {...props}
    >
      <div className="flex-column flex-center">
        <P className={`paragraph p-${props.page}`} style={{ zIndex: 1 }}>
          {props.children}
        </P>
      </div>
      {props.assets}
    </Page>
  );
};
