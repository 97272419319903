import { Loader } from "components/Loader/Loader";
import { Admin } from "pages/Admin/Admin";
import { Agario } from "pages/Agario/Agario";
import { Animation } from "pages/Animation/Animation";
import { Colors } from "pages/Colors/Colors";
import { Home } from "pages/Home/Home";
import { Horse } from "pages/Horse/Horse";
import { Meytvassa } from "pages/Meytvassa/Meytvassa";
import { Riff } from "pages/Meytvassa/Steps/Riff/Riff";
import { Accurate } from "pages/Play/Accurate";
import { FastText } from "pages/Play/FastText";
import { Sunrise } from "pages/Play/Sunrise";
import { Three } from "pages/Three/Three";
import { Navigate } from "react-router-dom";
import { getRandomInt } from "utils/maths";
import App from "./App";
const password = [...Array(6)].map(() => getRandomInt(9)).join("");
export const ROUTES = [
  {
    path: "/welcome/:password",
    name: "Welcome",
    hide: true,
    element: <App />,
  },
  {
    path: "/welcome/*",
    type: "redirect",
    element: <Navigate to={`/welcome/${password}`} />,
  },
  {
    path: "/admin",
    name: "Admin",
    element: <Admin />,
  },
  {
    path: "/colors",
    name: "Colors",
    hide: true,
    element: <Colors />,
  },
  // {
  //   path: "/home",
  //   name: "Home",
  //   element: <Home />,
  // },
  // {
  //   path: "/three",
  //   name: "Three",
  //   element: <Three />,
  // },
  // {
  //   path: "/agario",
  //   name: "Agario",
  //   element: <Agario />,
  // },
  // {
  //   path: "/animation",
  //   name: "Animation",
  //   standalone: true,
  //   element: <Animation />,
  // },
  {
    path: "/meytvassa",
    name: "Meytvassa",
    standalone: true,
    hide: true,
    element: <Meytvassa />,
  },
  {
    path: "/meytvassa/:id/riff",
    name: "Riff",
    standalone: true,
    hide: true,
    element: <Riff />,
  },
  {
    path: "/play",
    name: "Play",
    standalone: true,
    element: <Navigate to="/play/faster" />,
  },
  {
    path: "/play/faster",
    name: "Faster",
    hide: true,
    element: <FastText />,
  },
  {
    path: "/play/clearer",
    name: "Clearer",
    hide: true,
    element: <Accurate />,
  },
  {
    path: "/play/sunrise",
    name: "Sunrise",
    hide: true,
    element: <Sunrise />,
  },
  {
    path: "/maelle",
    name: "Horse",
    hide: true,
    element: <Horse />,
  },
  // {
  //   path: "/loader",
  //   name: "Loader",
  //   element: <Loader />,
  // },
];
