import { Button, Col, Row } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextTransition from "react-text-transition";
import { ColorProps } from "types/props";
import { Square } from "./BoardGame";
import "./colors.less";
import { Item } from "./Item";
import actions from "./services/actions";

export const Colors = (props: any) => {
  const state = useSelector((store: any) => store.colorStore as ColorProps);
  const dispatch = useDispatch();
  const [title, setTitle] = useState("Colors");
  const move = useCallback(
    (item: any, x: number, y: number) => {
      const newItemPosition = { ...item, x, y };
      dispatch(actions.move(newItemPosition));
    },
    [state.itemsPosition]
  );

  useEffect(() => {
    if (state.solved) setTitle("Well done");
    else setTitle("Colors");
  }, [state.solved]);
  return (
    <div className="flex-column">
      <TextTransition>
        <h1 className="gradient-emerald-text">{title}</h1>
      </TextTransition>
      <div
        className="flex-column flex-center"
        style={{ width: "max-content", alignSelf: "center" }}
      >
        <Row gutter={[5, 5]} className="board">
          {[...Array(state.col * state.row)].map((_, i) => {
            const x = i % state.col;
            const y = Math.floor(i / state.col);
            const childrenLength = state.itemsPosition.filter(
              (item) => item.x === x && item.y === y
            ).length;
            return (
              <Col span={24 / state.col} key={i}>
                <Square
                  accept={
                    state.accepts.find(
                      ({ cell_x, cell_y }) => x === cell_x && y === cell_y
                    )?.accept
                  }
                  childrenLength={childrenLength}
                  x={x}
                  y={y}
                  onDrop={(item) => move(item, x, y)}
                >
                  <>
                    {state.itemsPosition.map((item, j) =>
                      item.x === x && item.y === y ? (
                        <Item key={j} color={item.color} />
                      ) : undefined
                    )}
                  </>
                </Square>
              </Col>
            );
          })}
        </Row>

        <Button disabled={!state.solved} loading={!state.solved}>
          Continue
        </Button>
      </div>
    </div>
  );
};
