import { useEffect, useRef, useState } from "react";
import { getRandomWords } from "utils/maths";
import { CharWheel, CodeWheels } from "components";
import TextTransition from "react-text-transition";
import { Flex } from "antd";
import { useNavigate } from "react-router-dom";
import { lastValueFrom } from "rxjs";

const WIN_COUNT = 30;
const MAX_LEVEL = 20;
const getNewCode = (length: number) => {
  return getRandomWords(length);
};
function timeLeft(level: number) {
  const minTime = 1000; // Temps minimum pour le niveau 10
  const maxTime = MAX_LEVEL * 1000; // Temps maximum pour le niveau 1
  const maxLevel = MAX_LEVEL; // Niveau maximum

  if (level < 1 || level > maxLevel) {
    throw new Error("Le niveau doit être compris entre 1 et 10");
  }

  // Calculer le temps proportionnellement
  const time = maxTime - (level - 1) * ((maxTime - minTime) / 19);
  console.log(Math.round(time / 12), level);
  return Math.round(time / 12); // On arrondit au cas où
}

export const FastText = () => {
  const [secretCode, setSecretCode] = useState(getNewCode(3));
  const [score, setScore] = useState(
    localStorage.getItem("faster:score")
      ? Number(localStorage.getItem("faster:score"))
      : 0
  );
  const [level, setLevel] = useState(17);
  const [state, setState] = useState("initial");
  const secretCodeRef = useRef(secretCode);
  const [count, setCount] = useState(0);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const timerRef = useRef(timer);
  const [userInput, setUserInput] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    if (state === "initial") {
      setSecretCode(getNewCode(count + 2));
      setUserInput("");
    }
    if (state === "error") {
      setCount(0);
      setTimeout(() => {
        setState("initial");
      }, 1000);
    }
    if (state === "success") {
      setTimeout(() => {
        setState("initial");
        setCount(count + 1);
        if (count + 1 > score) {
          setScore(count + 1);
          localStorage.setItem("faster:score", (count + 1).toString());
        }
      }, 1000);
    }
  }, [state]);

  useEffect(() => {
    if (WIN_COUNT === count) {
      navigate("/play/colors");
    }
  }, [count]);
  useEffect(() => {
    secretCodeRef.current = secretCode;
  }, [secretCode]);
  useEffect(() => {
    timerRef.current = timer;
  }, [timer]);

  const handleKeyDown = (event: KeyboardEvent) => {
    const key = event.key.toLowerCase();
    const newUserInput = userInput + key;
    setUserInput(newUserInput);
    if (key === secretCodeRef.current[userInput.length]) {
      if (newUserInput !== secretCode) setState("texting");
    } else {
      setState("error");
    }
  };

  useEffect(() => {
    if (userInput === secretCode) {
      setState("success");
      timer && clearTimeout(timer);
    } else {
      setTimer(
        setTimeout(() => {
          if (state === "texting") setState("initial");
        }, timeLeft(level))
      );
    }
    document.addEventListener("keyup", handleKeyDown);
    return () => {
      timer && clearTimeout(timer);
      document.removeEventListener("keyup", handleKeyDown);
    };
  }, [userInput]);

  return (
    <div className="flex-center flex-column">
      {state === "initial" && (
        <h1 className="gradient-emerald-text">{secretCode}</h1>
      )}
      {["success", "error", "texting"].includes(state) && (
        <h1 className={`${state}`}>{userInput}</h1>
      )}

      <Flex
        gap="5px"
        className="white"
        style={{ position: "absolute", top: "100px" }}
      >
        <b>Result:</b>

        <span> {count} </span>
        <span>(highest: {score})</span>
      </Flex>
      <Flex
        gap="5px"
        className="white"
        style={{ position: "absolute", bottom: "100px" }}
      >
        <b>Level:</b>
        <CharWheel
          char={level.toString()}
          color={1}
          onWheelDown={() => {
            const levelDown = level - 1 <= 1 ? 1 : level - 1;
            if (level === levelDown) return;
            setLevel(levelDown);
            setUserInput("lvl down :(");
            setState("error");
          }}
          onWheelUp={() => {
            const levelUp = level + 1 >= MAX_LEVEL ? MAX_LEVEL : level + 1;
            if (level === levelUp) return;
            setLevel(levelUp);
            setUserInput("lvl up :)");
            setState("error");
          }}
        />
        ({timeLeft(level)}ms)
      </Flex>
    </div>
  );
};
