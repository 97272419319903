import center from "assets/images/destructured_color_logo/center.svg";
import shape from "assets/images/destructured_color_logo/shape.svg";
import shape_1 from "assets/images/destructured_color_logo/shape_explode/1.svg";
import shape_2 from "assets/images/destructured_color_logo/shape_explode/2.svg";
import shape_3 from "assets/images/destructured_color_logo/shape_explode/3.svg";
import shape_4 from "assets/images/destructured_color_logo/shape_explode/4.svg";
import side from "assets/images/destructured_color_logo/side.svg";
import tic from "assets/images/destructured_color_logo/tic.svg";
import { PropsWithChildren, useEffect, useState } from "react";
import "./destructuredLogo.less";
const initialShapes = [
  {
    name: "shape",
    src: shape,
    movement: "initial",
    explode: true,
  },
  {
    name: "tic",
    src: tic,
    movement: "initial",
  },
  {
    name: "center",
    src: center,
    movement: "initial",
  },
  {
    name: "side",
    src: side,
    movement: "initial",
  },
];

export const DestructuredLogo = (
  props: PropsWithChildren<{ movement?: string; loading: boolean }>
) => {
  const [shapes, setShapes] = useState(initialShapes);

  useEffect(() => {
    if (props.movement)
      setShapes(
        shapes.map((s) => ({ ...s, movement: props.movement as string }))
      );
  }, [props.movement]);
  const layers = [
    // "shadow",
    // "main",
    // "second",
    // "third",
    // "shadow fourth",
    "fourth",
  ];
  const miror_parts = ["up", "down"];
  return (
    <div className={`image-grower-box ${props.movement}`}>
      {layers.map((layer, key) => (
        <div
          key={key}
          className={`destructured-imagebox  ${
            props.loading ? "loading" : "unloading"
          } ${layer} ${props.movement}`}
        >
          {miror_parts.map((part, key) => (
            <div className={part} key={key}>
              {shapes.map((s, key) =>
                s.explode ? (
                  <>
                    <div className={`brick ${s.name} ${s.movement}`}>
                      <img src={shape_1} className="brick piece-1" />
                      <img src={shape_2} className="brick piece-2" />
                      <img src={shape_3} className="brick piece-3" />
                      <img src={shape_4} className="brick piece-4" />
                    </div>
                  </>
                ) : (
                  <img
                    key={key}
                    className={`brick ${s.name} ${s.movement}`}
                    src={s.src}
                    alt={s.name}
                  />
                )
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};
