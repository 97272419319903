import { omit } from "lodash";
import { DetailedHTMLProps, HTMLAttributes, PropsWithChildren } from "react";

export const H1 = (
  props: PropsWithChildren<
    DetailedHTMLProps<HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>
  > & { url?: string; shadow?: boolean }
) => {
  return (
    <div style={{ position: "relative" }} id={props.id}>
      <div
        {...omit(props, ["children", "id"])}
        className={`${props.className ?? ""} title-h1 shadow ${
          !props.shadow ? "no-shadow" : ""
        }`}
      >
        {props.children}
      </div>
      <div
        {...omit(props, ["children", "id"])}
        className={`${props.className} title-h1 ${props.url && " url"}`}
        style={{
          backgroundImage: `url("${props.url}")`,
          color: props.color,
        }}
      >
        {props.children}
      </div>
    </div>
  );
};

export const H2 = (
  props: PropsWithChildren<
    DetailedHTMLProps<HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>
  > & { url?: string; shadow?: boolean }
) => {
  return (
    <div style={{ position: "relative" }} id={props.id}>
      <div
        {...omit(props, ["children", "id"])}
        className={`${props.className ?? ""} shadow title-h2 ${
          !props.shadow ? "no-shadow" : ""
        }`}
      >
        {props.children}
      </div>
      <div
        {...omit(props, ["children", "id"])}
        className={`${props.className} title-h2 ${props.url && " url"}`}
        style={{
          backgroundImage: `url("${props.url}")`,
          color: props.color,
        }}
      >
        {props.children}
      </div>
    </div>
  );
  return (
    <div style={{ position: "relative" }} id={props.id}>
      {
        <h2
          {...omit(props, ["children", "id"])}
          className={`${props.className ?? ""} ui-h2 shadow ${
            !props.shadow ? "no-shadow" : ""
          }`}
        >
          {props.children}
        </h2>
      }
      <h2
        {...omit(props, ["children", "id"])}
        className={`${props.className} ui-h2`}
        style={{
          backgroundImage: `url("${props.url}")`,
          backgroundClip: "text",
        }}
      >
        {props.children}
      </h2>
    </div>
  );
};
