import logoText from "assets/images/logo_lock.svg";
import { Menu } from "components/Menu/Menu";
import { NotFound } from "pages/NotFound/NotFound";
import { useEffect, useState } from "react";
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  useLocation,
} from "react-router-dom";
import { ROUTES } from "./routes";

function Layout() {
  const location = useLocation();
  const { pathname } = location;
  const [alone, setAlone] = useState(false);

  useEffect(() => {
    ROUTES.find(({ standalone, path }) => {
      if (path === pathname) setAlone(standalone ?? false);
    });
  }, [pathname]);
  return (
    <>
      {alone ? (
        <>
          <Menu />
          <Outlet />
        </>
      ) : (
        <>
          <header></header>
          <Menu />

          <div className="main-container">
            <div className="flex-center">
              <Outlet />
            </div>
          </div>

          <footer>
            <img src="/images/logo_lock.svg" alt="Icone : logo" />
            <span className="copyright">© zedlitz 2024</span>
          </footer>
        </>
      )}
    </>
  );
}
const router = createBrowserRouter([
  {
    element: <Layout />,
    // errorElement: <ErrorPage />,
    children: ROUTES.map((route) => ({
      path: route.path,
      element: route.element,
    })),
  },
  {
    path: "/",
    element: <Navigate to="/admin" />,
  },
  {
    path: "/hum/*",
    element: <NotFound />,
  },
  {
    path: "*",
    element: (
      <Navigate to={`/hum/${window.location.pathname.replace("/", "")}`} />
    ),
  },
]);
export default router;
