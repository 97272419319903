import { call, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";

const db_home = "./data.json";
let default_db = {
  us: [
    {
      id: "Arthur",
      name: "Arthur",
      tresor: 0,
      gender: "male",
    },

    { id: "Manon", name: "Manon", tresor: 0, gender: "female" },
  ],
};

// Vérifier si le fichier JSON existe

function getDb() {
  return default_db;

  // if (fs.existsSync(db_home)) {
  //   // Lire le contenu du fichier JSON existant
  //   const content = fs.readFileSync(db_home, "utf8");
  //   const json = JSON.parse(content);
  //   console.log("Contenu actuel du fichier JSON :", json);
  //   return json;
  // } else {
  //   // Créer le fichier JSON avec le contenu par défaut
  //   fs.writeFileSync(db_home, JSON.stringify(default_db, null, 2));
  //   console.log(`Le fichier ${db_home} a été créé avec succès.`);
  //   return db_home;
  // }
}
function updateDb(newDb: any) {
  default_db = newDb;
  // var blob = new Blob([JSON.stringify(newDb)], { type: "application/json" });
  // saveAs(blob, "data.json");
  // fs.writeFileSync(db_home, JSON.stringify(newDb, null, 2));
  // console.log(`La base a été mise à jour.`);
}

async function add(action: any): Promise<any> {
  const db = getDb();
  const us = db.us.map((people: any) => {
    if (people.id === action.payload.id)
      return {
        ...people,
        tresor: people.tresor + action.payload.number,
      };
    else return people;
  });
  updateDb({ us });
  return Promise.resolve({ us });
}
async function extract(action: any): Promise<any> {
  const db = getDb();
  const us = db.us.map((people: any) => {
    if (people.id === action.payload.id)
      return {
        ...people,
        tresor: people.tresor - action.payload.number,
      };
    else return people;
  });
  updateDb({ us });
  return Promise.resolve({ us });
}
function* getDbRequest() {
  const db = getDb();
  yield put(actions.get_db_success(db));
}
function* addRequest(action: any): any {
  const response = yield call(add, action);
  yield put(actions.add_success(response));
}
function* extractRequest(action: any): any {
  const response = yield call(extract, action);
  yield put(actions.extract_success(response));
}

export function* homeSaga() {
  yield takeLatest("ADD_REQUEST", addRequest);
  yield takeLatest("EXTRACT_REQUEST", extractRequest);
  yield takeLatest("GET_DB_REQUEST", getDbRequest);
}
