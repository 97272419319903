import { Ornament } from "pages/Meytvassa/Steps/Prequel/Ornament";
import { PropsWithChildren, useState } from "react";
import TextTransition from "react-text-transition";
import { getRandomInt } from "utils/maths";
import { CODEX } from "utils/wheels";
import "./button.less";

export const ChoiceButton = (props: PropsWithChildren<any>) => {
  const [hover, setHover] = useState(false);
  const [text, setText] = useState<string>(props.children);
  return (
    <div
      className={"choice-button flex-center flex-column"}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={props.onClick}
    >
      {props.url && (
        <div
          className="image-bg"
          style={{ backgroundImage: `url("${props.url}")` }}
        />
      )}
      <div className="opacity-layer"></div>
      {/* <button> */}
      <div className="text-button">
        {text.split("").map((letter: any, i: number) => {
          if (letter === " ") {
            return <div style={{ width: "0.5em" }}></div>;
          }
          return (
            <TextTransition direction={i % 2 ? "up" : "down"}>
              <div>{hover ? letter : CODEX.alpha[getRandomInt(26)]}</div>
            </TextTransition>
          );
        })}
      </div>
      {/* 
      <TextTransition style={{ justifyContent: "center" }}>
        {hover ? props.children : "azeazeazeazraze"}
      </TextTransition> */}
      {/* </button> */}
      <Ornament anime={hover} />
    </div>
  );
};
