import { Button, Flex } from "antd";
import { useEffect, useState } from "react";
import TextTransition from "react-text-transition";

const WORDS = [
  "Hello...",
  "Maëlle, c'est ça ?",
  "Tu viens de télécharger un ransomeware.",
  "Tu peux encore éviter le pire.",
  "Mais attention, mon créateur ne rigole pas.",
  "Tu vas devoir faire un choix",
  "Red pills or Blue pills",
  "Mouhahahaha",
  "Nan je rigole",
  "mais tu vas devoir faire un choix",
  "Fais le bon.",
];
export const Horse = () => {
  const [step, setStep] = useState(1);
  const [stop, setStop] = useState(false);
  const [displayButton, setDisplayButton] = useState(false);
  const [title, setTitle] = useState(WORDS[0]);
  useEffect(() => {
    if (stop) return;
    if (step > WORDS.length) return;
    setTimeout(() => {
      setTitle(WORDS[step]);
      setStep(step + 1);
      if (step + 1 === WORDS.length + 1) {
        setDisplayButton(true);
      }
    }, 4000);
  }, [title]);
  useEffect(() => {
    if (localStorage.getItem("horse")) {
      setStop(true);
    }
  }, []);

  const handleClick = (who: string) => {
    setDisplayButton(false);

    if (who === "me") {
      localStorage.setItem("horse", "true");
      setTitle("Envoie moi ce code  : T1BG2OUF<3");
    } else {
      localStorage.setItem("horse", "false");
      setTitle("Mauvais choix.");
    }
  };

  return (
    <div className="align-center">
      {!stop && (
        <TextTransition>
          <h2 style={{ textAlign: "center" }} className="gradient-emerald-text">
            {title}
          </h2>
        </TextTransition>
      )}
      {displayButton && (
        <Flex gap={10}>
          <Button onClick={() => handleClick("geoguesser")}>Pole danse</Button>
          <Button onClick={() => handleClick("me")}>Un verre avec moi</Button>
        </Flex>
      )}
      <div className="flex-center">
        {stop && (
          <h2 className="gradient-emerald-text">t'as déjà répondu. Tant pis</h2>
        )}
      </div>
    </div>
  );
};
