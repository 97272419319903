import { wordsByLength } from "./dictionary";

export function getRandomInt(max: number) {
  return Math.floor(Math.random() * max);
}

const colors = [
  "#7c9296",
  "#d0eb96",
  "#ebbc96",
  "#95deea",
  "#d496eb",
  "#7ceb8a",
  "#ebcd7c",
  "#7c96eb",
  "#eb7c9b",
  "#60a4eb",
];
export function getRandomColor() {
  return colors[getRandomInt(colors.length - 1)];
}

export function getRandomWords(length: number) {
  const words: Array<string> =
    Object.values(wordsByLength)[length < 8 ? (!length ? 1 : length) : 7];
  return words[getRandomInt(words.length - 1)];
}
export function round(num: number): number {
  var m = Number((Math.abs(num) * 100).toPrecision(15));
  return (Math.round(m) / 100) * Math.sign(num);
}
