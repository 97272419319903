import { Button, Input } from "antd";
import { CodeWheels } from "components";
import { ChangeEvent, useEffect, useState } from "react";
import TextTransition from "react-text-transition";
import { CodeProps } from "types/props";
import { unlockNoise } from "utils/wheels";

interface CodeWheel {
  code: string; // le code
  solved?: boolean; // Terminé ?
  randomStart?: boolean; // wheel automatique au départ ?
  muffler?: boolean; // Silencieux
  wheelAll?: boolean; // Rouler tout en en roulant une ?
  type?: "alpha" | "numeric" | "alphanum"; // Type de code
  randomStartLoop?: number; // Combien de wheel automatique
  lockCharAfterSolved?: boolean; // locker le char dès qu'il est trouvé
}
const welcomeCode = [
  {
    code: "welcome",
    randomStart: true,
    wheelAll: true,
  },
  {
    code: "to",
    randomStart: true,
    wheelAll: false,
    lockCharAfterSolved: true,
  },
  {
    code: "my",
    randomStart: true,
    wheelAll: false,
    muffler: true,
    lockCharAfterSolved: true,
  },
  {
    code: "website",
    randomStart: true,
    wheelAll: true,
  },
];
export const Code = (props: CodeProps) => {
  const [disabled, setDisabled] = useState(true);
  const [welcomeText, setWelcomeText] = useState("Continue");
  const [code, setCode] = useState<CodeWheel[]>(welcomeCode);
  const [title, setTitle] = useState("Hi, ya.");
  const [step, setStep] = useState(0);
  const [currentText, setCurrentText] = useState("");
  const [username, setUsername] = useState<string | null>(null);

  useEffect(() => {
    if (!code.length) return;
    const unlock = code.reduce(
      (solves: boolean, word: CodeWheel) => solves && (word as any)?.solved,
      true
    );
    if (unlock) {
      unlockNoise();
      step !== 1 && setDisabled(false);
    }
  }, [code]);

  useEffect(() => {
    switch (step) {
      case 1:
        setTitle("Well, who am I ?");
        setCode([
          {
            code: "zedlitz",
            randomStart: true,
            randomStartLoop: 50,
            // muffler: true,
            // wheelAll: true,
            lockCharAfterSolved: true,
          },
        ]);
        setDisabled(true);
        break;
      case 2:
        setTitle("What's yours ?");
        setCode([]);
        setDisabled(true);
        break;
      case 3:
        setTitle(`Enter your code`);
        setCode([
          {
            code: props.password,
            randomStart: true,
            muffler: true,
            type: "numeric",
          },
        ]);
        setDisabled(true);
        break;
      case 4:
        props.onSolved();
        break;
      default:
        break;
    }
  }, [step]);

  const solved = (codeSolved: string) => {
    setCode(
      code.map((word) => {
        if (word.code === codeSolved) {
          return { ...word, solved: true };
        } else return word;
      })
    );

    if (step === 1) {
      setTimeout(() => {
        setTitle("Oh...");
      }, 1000);
      setTimeout(() => {
        setTitle("I've helped.");
        setDisabled(false);
      }, 2000);
    }
  };

  const codeWords = code.map((word, i) => (
    <CodeWheels
      key={i}
      {...word}
      onChange={(currentText) => {
        setCurrentText(currentText);
      }}
      disabled={word.solved}
      onSolved={() => {
        solved(word.code);
      }}
    />
  ));
  return (
    <section className="main-container">
      <div className="flex-center">
        <div className="flex-column">
          <h3>
            <TextTransition>{title}</TextTransition>
          </h3>

          {codeWords}
          {step === 2 && (
            <Input
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setDisabled(!e.target.value.length);
                setUsername(e.target.value);
              }}
            />
          )}

          <Button
            disabled={disabled}
            loading={disabled}
            type="primary"
            onClick={() => {
              setStep(step + 1);
            }}
          >
            {welcomeText}
          </Button>
        </div>
      </div>
    </section>
  );
};
