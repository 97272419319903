import anime from "animejs";
import { HorizontalPages, Page } from "components/Contents";
import { H1, H2 } from "components/Html";
import { Scroller, ScrollerContext } from "components/Scroller/Scroller";
import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FreehandMap } from "../Prequel/Ornament";
import { Paragraph } from "../Prequel/Paragraph";
import "./../../meytvassa.less";
import "./riff.less";
export const Riff = (props: any) => {
  const { id } = useParams();
  return (
    <div
      id="meytvassa-history"
      className="bg-black"
      style={{ width: "100vw", overflowX: "hidden" }}
    >
      <Scroller>
        <div style={{ height: "100vh" }}>
          {/* Cover 2 pages */}
          <RiffTitle page={0} />
          <HorizontalPages page={1}>
            <Page
              overflow={"hidden"}
              layer
              backgroundLayer="linear-gradient(0deg, transparent 0%, #010719 100%)"
              animeBackground
              parallax={0.3}
              url="/images/sea_pattern.jpeg"
              backgroundImageStyle={{
                backgroundRepeat: "repeat",
                backgroundSize: "200px",
              }}
            >
              <FreehandMap page={1} anime />
            </Page>
            <div />
          </HorizontalPages>

          <Paragraph shadow page={2}>
            Les côtes de la nouvelle terre sont sauvages.
            <br />
          </Paragraph>
        </div>
      </Scroller>
    </div>
  );
};

const RiffTitle = (props: any) => {
  const { initScroller } = useContext(ScrollerContext);

  useEffect(() => {
    anime({
      targets: "#main-title",
      translateY: [100, 0],
      easing: "easeInOutExpo",
      opacity: [0, 1],
      duration: 2000,
    });
    // const scroller = initScroller();
    // scroller && scroller.on("scroll", () => {});
  }, []);

  return (
    <Page
      overflow={"hidden"}
      layer
      backgroundLayer="linear-gradient(0deg, transparent 0%, #010719 100%)"
      animeBackground
      parallax={0.2}
      url="/images/riff.jpeg"
    >
      <div className="flex-center">
        <H1 shadow color="white" id="main-title" url="/images/riff.jpeg">
          Les côtes d'Artémis
        </H1>
      </div>
    </Page>
  );
};
