import { Flex, Input, InputProps, Space } from "antd";
import { useEffect, useRef, useState } from "react";

import "./code.less";

export interface CodeInputProps {
  length?: number;
  onComplete: (str: string) => void;
}

export const Code = ({ length = 4, ...props }: InputProps & CodeInputProps) => {
  const [values, setValues] = useState(Array(length).fill(""));
  const inputsRef = useRef<any>([]);

  useEffect(() => {
    setTimeout(() => {
      (document.getElementById("code-nth-0") as HTMLInputElement)?.focus();
    }, 200);
  }, []);

  const handleChange = (value: any, index: number) => {
    const newValues = [...values];
    newValues[index] = value;
    setValues(newValues);

    // Passer automatiquement au champ suivant si une valeur est saisie
    if (value && index < length - 1) {
      (inputsRef.current[index + 1] as HTMLInputElement).focus();
    }
    // Appeler onComplete si tous les champs sont remplis
    if (newValues.every((val) => val !== "") && props.onComplete) {
      props.onComplete(newValues.join(""));
    }
  };

  const handleKeyDown = (e: any, index: number) => {
    if (e.key === "Backspace" && !values[index] && index > 0) {
      // Revenir au champ précédent si "Backspace" est pressé sur un champ vide
      (inputsRef.current[index - 1] as HTMLInputElement).focus();
    }
  };

  const handleFocus = (e: any) => {
    e.target.select(); // Sélectionne automatiquement le contenu du champ
  };

  return (
    <Flex justify="space-between" gap={3}>
      {values.map((value, index) => (
        <Input
          {...props}
          id={`code-nth-${index}`}
          key={index}
          required
          className={`${props.className} code`}
          type="text"
          maxLength={1}
          value={value}
          onChange={(e) => handleChange(e.target.value, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          onFocus={handleFocus}
          ref={(el) => (inputsRef.current[index] = el)} // Garde une référence pour chaque input
          style={{
            width: "40px",
            height: "40px",
            textAlign: "center",
            fontSize: "18px",
            border: "1px solid #ccc",
            borderRadius: "5px",
          }}
        />
      ))}
    </Flex>
  );
};
